import React, { useEffect, useRef, useState } from "react";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "../../Help/_help.module.css";
import Moment from "react-moment";
import useDownloader from "react-use-downloader";
import { GiWideArrowDunk } from "react-icons/gi";
import { Modal, Button } from "rsuite";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";

const ChatComp = props => {
  const { section, helpUser, student, title } = props.propsActualData;
  const { selectedHelpUser, selectedTitle } = props.propsData;

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const imgRef = useRef(null);
  let scrollRef = useRef();

  const [previewImage, setPreviewImage] = useState("");

  const [message, setMessages] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState(false);

  const [open, setOpen] = useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");

  const [openImg, setOpenImg] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };
  const handleMouseUp = () => {
    setDragging(false);
  };
  const handleMouseLeave = () => {
    setDragging(false);
  };
  const { download } = useDownloader();
  const handleClose = () => {
    setOpen(false);
  };

  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoadingMsg(true);

        const payload = { requestId: selectedTitle, userId: selectedHelpUser };

        const x = await AxiosInstance.post(
          `/help/getChatTitleUsersAdmin`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        x?.data[0]?.chat?.reverse();
        let data = x?.data[0]?.chat;
        if (x?.data[0]?.chat?.length !== 0) {
          setMessages(data);
        }
        setLoadingMsg(false);
      } catch (error) {
        console.log(error);
        setLoadingMsg(false);
      }
    };
    fetchMessages();
  }, [selectedTitle, selectedHelpUser]);

  return (
    <>
      <div
        style={{
          height: "10%",
          width: "100%",
          background: "#D7DBDD",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div className={Styles.adminHelpHeader}>
          <p>Section : </p> <small>{section}</small>
        </div>
        <div className={Styles.adminHelpHeader}>
          <p>User : </p> <small>{helpUser?.username}</small>
        </div>
        <div className={Styles.adminHelpHeader}>
          <p>Student : </p>
          <small>{student?.username}</small>
        </div>
        <div className={Styles.adminHelpHeader}>
          <p>Ticket : </p>
          <small>{title?.title}</small>
        </div>
      </div>
      {loadingMsg ? (
        <div
          style={{
            height: "90%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ThreeDots color="#f16f1d" height={80} width={80} />
        </div>
      ) : (
        <aside
          style={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <div className={Styles.chat}>
            {message?.length === 0 ? (
              <span className={Styles.noConversation}>
                <p style={{ color: "#f16f1d", textAlign: "center" }}>
                  No Messages
                </p>
              </span>
            ) : (
              message.map((messages, ind) => {
                return (
                  <div
                    key={ind + 1}
                    className={
                      messages?.senderId === selectedHelpUser
                        ? `${Styles.sendermsg}`
                        : `${Styles.recivermsg}`
                    }
                  >
                    <Modal onClose={handleClose} open={open} size="full">
                      <Modal.Body>
                        <div
                          style={{
                            overflow: "hidden",
                            cursor: zoomLevel > 1 ? "grab" : "auto",
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img
                            ref={imgRef}
                            src={previewImage}
                            style={{
                              transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                              transition: "transform 0.3s ease",
                              maxWidth: "700px",
                              maxHeight: "80%",
                              width: "80%",
                              margin: "auto",
                              display: "block",
                              // objectFit: "cover",
                            }}
                            alt="Preview"
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleZoomOut}>
                          <FaSearchMinus />
                        </Button>
                        <Button variant="secondary" onClick={handleZoomIn}>
                          <FaSearchPlus />
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <main
                      style={{ backgroundColor: backgroundColor1 }}
                      onClick={e => {
                        messages?.poll === null &&
                          setBackgroundColor1(
                            backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : ""
                          );
                      }}
                    >
                      <div>
                        <p className={Styles.textmsg}>
                          {messages?.message?.text ? (
                            messages?.message?.text
                          ) : messages?.message?.files?.length > 0 ? (
                            <>
                              {(() => {
                                switch (messages?.message?.files[0]?.mimetype) {
                                  case "image/jpg":
                                  case "image/jpeg":
                                  case "image/avif":
                                  case "image/png":
                                  case "image/webp":
                                  case "image/tiff":
                                  case "image/bmp":
                                    return (
                                      <>
                                        <img
                                          src={`https://testchatb.qspiders.com/${messages?.message?.files[0]?.thumbnail_path}`}
                                          alt=""
                                          style={{
                                            width: "200px",
                                            height: "130px",
                                            borderRadius: "5px",
                                            display: "flex",
                                          }}
                                          onClick={e => {
                                            // stopPropagation(e);
                                            handleImage(
                                              `http://192.168.1.202:4545/${messages?.message?.files[0]?.path}``https://testchatb.qspiders.com/${messages?.message?.files[0]?.path}`
                                            );
                                          }}
                                        />{" "}
                                        <button
                                          className={Styles.btndownload1}
                                          onClick={() =>
                                            download(
                                              `http://192.168.1.202:4545/${messages?.message?.files[0]?.path}`,
                                              `https://testchatb.qspiders.com/${messages?.message?.files[0]?.path}`,
                                              messages?.message?.files[0]
                                                ?.originalname,
                                              messages?.message?.files[0]
                                                ?.filename
                                            )
                                          }
                                        >
                                          {
                                            messages?.message?.files[0]
                                              ?.originalname
                                          }
                                          <span
                                            className={Styles.Download}
                                          ></span>
                                          <span className={Styles.linkBlock}>
                                            Click to download
                                          </span>
                                        </button>
                                        {/* {messages.broadcastchat ? ( */}
                                        <>
                                          <span>
                                            {message?.forwarded ? (
                                              <span
                                                className={Styles.broadcast}
                                              >
                                                <GiWideArrowDunk />
                                                {/* {message.broadcastchat} */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      </>
                                    );
                                  case "audio/mpeg":
                                  case "audio/mp3":
                                  case "audio/webm":
                                  case "application/wav":
                                  case "video/webm":
                                  case "audio/wav":
                                    return (
                                      <>
                                        <audio
                                          src={`https://testchatb.qspiders.com/${messages?.message?.files[0].path}`}
                                          controls
                                          style={{
                                            width: "250px",
                                            height: "40px",
                                          }}
                                        ></audio>
                                        <>
                                          <span>
                                            {messages.forwarded ? (
                                              <span
                                                className={Styles.broadcast}
                                              >
                                                <GiWideArrowDunk />
                                                {/* {message.broadcastchat} */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      </>
                                    );
                                  case "video/mp4":
                                  case "video/x-matroska":
                                  case "application/docx":
                                  case "application/doc":
                                  case "application/msword":
                                  case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                  case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                                  case "application/vnd.ms-word.document.macroEnabled.12":
                                  case "application/vnd.ms-excel":
                                  case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                  case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                                  case "application/vnd.ms-excel.sheet.macroEnabled.12":
                                  case "application/vnd.ms-powerpoint":
                                  case "application/xlsx":
                                  case "application/pptx":
                                  case "application/octet-stream":
                                  case "text/plain":
                                  case "text/html":
                                  case "application/apk":
                                  case "application/pdf":
                                  case "application/x-pdf":
                                  case "application/x-gzpdf":
                                  case "applications/vnd.pdf":
                                  case "application/acrobat":
                                  case "application/x-google-chrome-pdf":
                                  case "text/pdf":
                                  case "text/x-pdf":
                                  case "text/csv":
                                  case "application/zip":
                                  case "application/x-zip":
                                  case "application/x-zip-compressed":
                                  case "application/json":
                                    return (
                                      <>
                                        <button
                                          className={Styles.btndownload1}
                                          onClick={() =>
                                            download(
                                              `http://192.168.1.202:4545/${messages?.message?.files[0]?.path}`,
                                              `https://testchatb.qspiders.com/${messages?.message?.files[0]?.path}`,
                                              messages?.message?.files[0]
                                                ?.originalname,
                                              messages?.message?.files[0]
                                                ?.filename
                                            )
                                          }
                                        >
                                          {
                                            messages?.message?.files[0]
                                              ?.originalname
                                          }
                                          <span
                                            className={Styles.Download}
                                          ></span>
                                          <span className={Styles.linkBlock}>
                                            Click to download
                                          </span>
                                        </button>
                                        <>
                                          <span>
                                            {messages?.forwarded ? (
                                              <span
                                                className={Styles.broadcast}
                                              >
                                                <GiWideArrowDunk />
                                                {/* {message.broadcastchat} */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      </>
                                    );
                                  default:
                                    return (
                                      <button
                                        className={Styles.btndownload1}
                                        onClick={() =>
                                          download(
                                            `http://192.168.1.202:4545/${messages?.message?.files[0]?.path}`,
                                            `https://testchatb.qspiders.com/${messages?.message?.files[0]?.path}`,
                                            messages?.message?.files[0]
                                              ?.originalname,
                                            messages?.message?.files[0]
                                              ?.filename
                                          )
                                        }
                                      >
                                        {
                                          messages?.message?.files[0]
                                            ?.originalname
                                        }
                                        <span
                                          className={Styles.Download}
                                        ></span>
                                        <span className={Styles.linkBlock}>
                                          Click to download
                                        </span>
                                      </button>
                                    );
                                }
                              })()}
                            </>
                          ) : null}
                        </p>
                      </div>
                      <div id={Styles.FooterBlock}>
                        <span></span>
                        <small
                          className={Styles.namesender}
                          style={{ color: "red" }}
                        >
                          {messages?.senderName}
                        </small>
                        <div className={Styles.messageBottom}>
                          {Date.now ? (
                            <Moment format="MMM DD - h:mm a">
                              {messages?.createdAt}
                            </Moment>
                          ) : (
                            <Moment format="MMM DD YYYY h A">
                              {messages?.createdAt}
                            </Moment>
                          )}
                        </div>
                      </div>
                    </main>
                  </div>
                );
              })
            )}
          </div>
        </aside>
      )}
    </>
  );
};

export default ChatComp;
