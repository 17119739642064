import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Styles from "./_chat.module.css";
import { HiSpeakerphone } from "react-icons/hi";
import { GiWideArrowDunk } from "react-icons/gi";
import useDownloader from "react-use-downloader";
import Linkify from "react-linkify";
import fetchImageUrl from "../../functions/FetchFileWithToken";

const Message = ({ message, own, particularStudent }) => {
  const { download } = useDownloader();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const url = text.match(urlRegex);
    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }

    return (
      <Linkify componentDecorator={openInNewTab}>
        <span
          className={Styles.messageText}
          style={{ wordBreak: "break-word" }}
        >
          {message?.messages?.text} <br />
          {(message.broadcastchat && message.forwarded) || message.forwarded ? (
            <span className={Styles.broadcast}>
              <GiWideArrowDunk />
            </span>
          ) : message.broadcastchat ? (
            <span className={Styles.broadcast}>
              <HiSpeakerphone />
            </span>
          ) : (
            ""
          )}
        </span>
      </Linkify>
    );
  }

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      if (message?.messages?.files !== undefined) {
        const fileData = await fetchImageUrl(
          message?.messages?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
      if (message?.status && message?.status?.message?.files != undefined) {
        const fileData = await fetchImageUrl(
          message?.status?.message?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }

      if (
        message?.replayback &&
        Object.keys(message?.replayback).length > 0 &&
        message?.replayback?.file !== ""
      ) {
        const fileData = await fetchImageUrl(
          message?.replayback?.file[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  return (
    <>
      <div
        className={
          message?.messages?.text !== ""
            ? `${Styles.Messageeee}`
            : `${Styles.Emptymsg}`
        }
      >
        <div
          className={
            own
              ? `${Styles.sendermsg}`
              : message?.sender === particularStudent?.studentId
              ? `${Styles.recivermsg}`
              : `${Styles.displaynone}`
          }
        >
          {/* id={  ? `${Styles.displaynone}`:`${Styles.blockmsg}`} */}
          <main>
            <div className={Styles.messageTop}>
              {message?.messages?.text ? (
                <p className={Styles.messageText}>
                  {message ? linkify(message?.messages?.text) : ""}
                </p>
              ) : message?.messages?.files?.length > 0 ? (
                <>
                  {(() => {
                    switch (message?.messages?.files[0]?.mimetype) {
                      case "image/jpg":
                      case "image/jpeg":
                      case "image/avif":
                      case "image/png":
                      case "image/webp":
                      case "image/tiff":
                      case "image/bmp":
                        return (
                          <img
                            src={imageUrl?.url}
                            alt=""
                            style={{
                              width: "250px",
                              borderRadius: "5px",
                            }}
                          />
                        );
                      case "application/docx":
                      case "application/doc":
                      case "application/msword":
                      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                      case "application/vnd.ms-word.document.macroEnabled.12":
                      case "application/vnd.ms-excel":
                      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                      case "application/vnd.ms-excel.sheet.macroEnabled.12":
                      case "application/vnd.ms-powerpoint":
                      case "application/xlsx":
                      case "application/pptx":
                      case "text/plain":
                      case "text/html":
                      case "application/apk":
                        return (
                          <a
                            className={Styles.Document}
                            href={`https://testchatb.qspiders.com/${message?.messages?.files[0].path}`}
                          >
                            {message?.messages?.files[0].originalname}
                            <span className={Styles.Download}>
                              Click to download
                            </span>
                          </a>
                        );

                      case "application/x-pdf":
                      case "application/x-gzpdf":
                      case "applications/vnd.pdf":
                      case "application/acrobat":
                      case "application/x-google-chrome-pdf":
                      case "text/pdf":
                      case "text/x-pdf":
                      case "text/csv":
                      case "application/zip":
                      case "application/x-zip":
                      case "application/octet-stream":
                      case "application/x-zip-compressed":
                        return (
                          <>
                            <a
                              className={Styles.Document}
                              href={`https://testchatb.qspiders.com/${message?.messages?.files[0].path}`}
                            >
                              {message?.messages?.files[0].originalname}
                              <span className={Styles.Download}>
                                Click to download
                              </span>
                            </a>
                          </>
                        );
                      case "video/mp4":
                      case "video/x-matroska":
                        return (
                          <>
                            <button className={Styles.btndownload1}>
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    `https://testchatb.qspiders.com/${message?.messages?.files[0].path}`,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                            {message.broadcastchat ? (
                              <>
                                <span>
                                  {(message.broadcastchat &&
                                    message.forwarded) ||
                                  message.forwarded ? (
                                    <span className={Styles.broadcast}>
                                      <GiWideArrowDunk />
                                      {message.broadcastchat}
                                    </span>
                                  ) : message.broadcastchat ? (
                                    <span className={Styles.broadcast}>
                                      <HiSpeakerphone />
                                      {message.broadcastchat}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      case "audio/mpeg":
                        return <audio src={imageUrl?.url} controls></audio>;
                      case "application/pdf":
                        return (
                          <>
                            <embed
                              src={imageUrl?.url}
                              style={{
                                overflow: "hidden !important",
                                width: "100%",
                                height: "100%",
                                type: "json",
                              }}
                            ></embed>
                            <a
                              href={`https://testchatb.qspiders.com/${message?.messages?.files[0].path}`}
                            >
                              download
                            </a>
                          </>
                        );
                    }
                  })()}
                </>
              ) : (
                <h1>oops</h1>
              )}
            </div>
            <div className={Styles.messageBottom}>
              <Moment fromNow>{message?.createdAt}</Moment>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Message;
