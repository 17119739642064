import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AxiosInstance, { BddtAxios } from "../../api/AxiosInstance";
import Style from "./batch.module.css";
import Styles from "./batch.module.css";
import { toast } from "react-toastify";
import { SocketContext } from "../../ioContext/socketIo";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  Modal as MUIModal,
  Typography,
  Checkbox,
  Divider,
  Button as MUIButton,
  FormControlLabel,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { TableContainer, TableHead, TableRow } from "@mui/material";
import { styled, tableCellClasses, Button } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const StudentList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  let [allstudents, setAllStudents] = useState([]);
  let [defaultStudents, setDefaultStudents] = useState([]);
  const [countedData, setCountedData] = useState(0);

  const [filtered, setFiltered] = useState({ x: false, term: "" });
  let [loadUser, setLoadUser] = useState(false);
  const [loadSearch, setLoadSearch] = useState(false);

  let [search, setSearch] = useState("");
  let [clickSearch, setClickSearch] = useState(false);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const [open, setOpen] = React.useState(false);
  let [selectedStudent, setSelectedStudent] = useState([]);
  let [batches, setBatches] = useState([]);

  let [raiting, setRaiting] = useState([]);
  let [raitingLoading, setRatingLoading] = useState([]);
  let [mock, setMock] = useState([]);
  let [mockLoading, setMockLoading] = useState([]);
  let [Subject, setSubject] = useState([]);
  let [subjectLoading, setSubjectLoading] = useState([]);

  let [batchLoading, setBatchLoading] = useState([]);
  let [studentid, setStudentId] = useState([]);
  let [studentaid, setStudentAid] = useState([]);
  const [oldOrNot, setOldOrNot] = useState("");
  let [oldBatchList, setOldBatchList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const socket = useContext(SocketContext);
  let [finalmock, setFinalMock] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setIsChecked(false);
    setFinalMock(false);
    setOpen(false);
  };

  let bddtUrl = `https://hrstbackend.qspiders.com/`;

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [inputData, setInputData] = useState({
    technical: "",
    subject: null,
    communication: "",
    newBatches: null,
    oldBatches: null,
    mockLevel: "",
    checkbox1: false,
    checkbox2: false,
    comment: "",
  });

  const {
    technical,
    subject,
    communication,
    newBatches,
    oldBatches,
    mockLevel,
    checkbox1,
    checkbox2,
    comment,
  } = inputData;

  const handleChangeAllField = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };

  const handleChangeAllChk = (name, value) => {
    if (name === "checkbox1") {
      setInputData({ ...inputData, [name]: value, checkbox2: false });
    } else {
      setInputData({ ...inputData, [name]: value, checkbox1: false });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (oldOrNot && isChecked) {
      if (subject === null) {
        toast.warning("Select Subject");
      } else if (oldBatches === null) {
        toast.warning("Select Batch");
      } else if (technical === "") {
        toast.warning("Select Technical Rating");
      } else if (communication === "") {
        toast.warning("Select Communication Rating");
      } else if (!checkbox1 && !checkbox2) {
        toast.warning("Select Mock");
      } else if (checkbox2 && mockLevel === "") {
        toast.warning("Select Mock Level");
      } else {
        setDisableSubmit(true);

        let payload = {
          token: user.TOKEN,
          student: studentid,
          batch: null,
          taken_trainer: true,
          communication: communication,
          subject: subject.value,
          comment: comment,
          final_mock: checkbox1,
          pre_mock: checkbox2,
          mock_level: checkbox2 ? mockLevel : null,
          taken_trainer: true,
          technical: technical,
          trainer: user.dbbranchuserid,
          qTalk: true,
          batch_text: oldBatches.value === false ? null : [oldBatches.value],
        };

        await BddtAxios.post(`hr/addstudentmock/`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            toast.success(x.data.message);
            handleClose();
            setDisableSubmit(false);

            setInputData({
              technical: "",
              subject: null,
              communication: "",
              newBatches: null,
              oldBatches: null,
              mockLevel: "",
              checkbox1: false,
              checkbox2: false,
              comment: "",
            });
          })
          .catch(y => {});
      }
    } else {
      if (newBatches === null) {
        toast.warning("Select Batch");
      } else if (technical === "") {
        toast.warning("Select Technical Rating");
      } else if (communication === "") {
        toast.warning("Select Communication Rating");
      } else if (!checkbox1 && !checkbox2) {
        toast.warning("Select Mock");
      } else if (checkbox2 && mockLevel === "") {
        toast.warning("Select Mock Level");
      } else {
        setDisableSubmit(true);

        let payload = {
          token: user.TOKEN,
          student: studentid,
          batch: newBatches.value === false ? null : newBatches.value,
          communication: communication,
          comment: comment,
          final_mock: checkbox1,
          pre_mock: checkbox2,
          mock_level: checkbox2 ? mockLevel : null,
          taken_trainer: true,
          technical: technical,
          trainer: user.dbbranchuserid,
          qTalk: true,
        };

        await BddtAxios.post(`hr/addstudentmock/`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            toast.success(x.data.message);
            handleClose();
            setDisableSubmit(false);

            setInputData({
              technical: "",
              subject: null,
              communication: "",
              newBatches: null,
              oldBatches: null,
              mockLevel: "",
              checkbox1: false,
              checkbox2: false,
              comment: "",
            });
          })
          .catch(y => {});
      }
    }
  };

  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, [socket]);
  // useEffect(() => {
  //   let data = selectedStudent?.batchdata?.map(x => {
  //     return { value: x.aid, label: x.batchname };
  //   });

  //   setBatches(data);
  // }, [selectedStudent]);
  let LoadRating = () => {
    async function fetchData() {
      await BddtAxios.get(`/hr/ratinglist/?token=${user.TOKEN}`)
        .then(x => {
          let trainersss = x?.data.results;

          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setRaiting(eee);
        })
        .catch(y => {})
        .finally(() => {
          setRatingLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenMock = () => {
    setMockLoading(true);
    LoadMock();
  };
  const handleMenuOpenTrainer = () => {
    // if (raiting.length === 0 && !raitingLoading) {
    setRatingLoading(true);
    LoadRating();
    // }
  };
  let LoadMock = () => {
    async function fetchData() {
      await BddtAxios.get(`/familiar/mocklevelget/?token=${user.TOKEN}`)
        .then(x => {
          let trainersss = x?.data.results;
          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setMock(eee);
        })
        .catch(y => {})
        .finally(() => {
          setSubjectLoading(false);
        });
    }
    fetchData();
  };

  let LoadBatch = () => {
    async function fetchData() {
      let traineraid = "Q" + " " + "and" + " " + "Q" + user?.dbbranchuserid;

      await BddtAxios.get(
        `/hr/branchcode/?token=${user.TOKEN}&stud_a_id=${studentaid}&train_a_id=${traineraid}`
      )
        .then(x => {
          let trainersss = x?.data.results;
          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });

          setBatches(eee);
        })
        .catch(y => {})
        .finally(() => {
          setBatchLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBatch = () => {
    setBatchLoading(true);
    LoadBatch();
  };

  let LoadSubject = () => {
    async function fetchData() {
      // //(BddtAxios);
      let payload = {
        url: `${bddtUrl}/courses/subject/?name=${searchQuery}`,
      };

      await BddtAxios.get(
        `/courses/subject/?name=${searchQuery}&token=${user.TOKEN}`
      )
        .then(x => {
          let trainersss = x?.data.results;
          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setSubject(eee);
        })
        .catch(y => {})
        .finally(() => {
          setSubjectLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenSubject = () => {
    // if (raiting.length === 0 && !raitingLoading) {
    setSubjectLoading(true);
    LoadSubject();
    // }
  };
  useEffect(() => {
    if (searchQuery.length > 2) {
      setSubjectLoading(true);

      LoadSubject();
    }
  }, []);

  let HandleProfile = num => {
    async function fetchlist() {
      if (num) {
        await BddtAxios.get(
          `/hr/student-personaldetails/?number=${num}&token=${user?.TOKEN}`
        )
          .then(x => {
            let data = x?.data?.results;
            let id = x?.data?.results[0]?.id;
            let aid = x?.data?.results[0]?.a_id;
            let old = x?.data?.results[0]?.old_student;
            let batch = x?.data?.results[0]?.batch;

            setOldOrNot(old);
            if (old === true) {
              let list = batch.map(x => {
                return x.batch_text !== null && x.batch_text.split(";");
              });

              let filteredArray = Object.values(list)
                .filter(value => Array.isArray(value))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator.concat(currentValue),
                  []
                );

              let final = filteredArray.map((ele, ind) => {
                return { value: ele, label: ele };
              });
              setOldBatchList(final);
            }

            if (data?.length > 0) {
              handleOpen();
              setStudentId(id);
              setStudentAid(aid);
            } else {
              toast.error("Student profile  not Updated", {
                position: "top-right",
              });
            }
          })
          .catch(y => {});
      }
    }
    fetchlist();
  };
  useEffect(() => {
    async function fetchData() {
      setLoadUser(true);
      try {
        let StudentData = await AxiosInstance.get(`users/getstudents`, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        });
        let Studentdataaa = StudentData?.data?.students;

        setAllStudents(Studentdataaa);
        setDefaultStudents(Studentdataaa);
        setLoadUser(false);
      } catch (error) {
        setLoadUser(null);
      }
    }
    fetchData();
  }, []);

  const handleChangePage = async e => {
    let scroolVal = e;
    try {
      let StudentData = await AxiosInstance.get(
        `users/getstudents?scroll=${scroolVal}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      let Studentdataaa = StudentData?.data?.students;
      setAllStudents(Studentdataaa);
      setDefaultStudents(Studentdataaa);
      setLoadUser(false);
    } catch (error) {
      setLoadUser(null);
    }
  };

  // search changes
  const handleReset = () => {
    setAllStudents(defaultStudents);
    setFiltered({ x: false, term: "" });
    setSearch("");
  };

  const handleSearchClick = async () => {
    let searchVal = search === "" ? "" : `?search=${search}`;

    if (search.length > 2) {
      setLoadSearch(true);
      try {
        const { data } = await AxiosInstance.get(
          `users/getstudents${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        setAllStudents(data.students === null ? [] : data.students);
        setLoadSearch(false);
        setFiltered({ x: true, term: search });
      } catch (error) {
        setLoadSearch(false);
        toast.error(error || "something went wrong");
      }
    } else {
      toast.error("search with minimum 3 characters");
      setLoadSearch(false);
    }
  };

  let handleSearch = e => {
    setSearch(e.target.value);
  };

  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (search.trim() !== "") {
        // Trigger search if input is not empty
        handleSearchClick();
      }
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && search.length === 1) {
      setAllStudents(defaultStudents);
      setFiltered({ x: false, term: "" });
      setSearch("");
    }
  };
  let HandleSearchClick = () => {
    if (search.length > 0) {
      setClickSearch(true);
    }
  };
  let HandleClickClick = () => {
    setClickSearch(false);
    setSearch("");
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      {/* modal for update mock */}
      <MUIModal open={open} onClose={handleClose}>
        <Box sx={style} position="relative">
          <button
            type="button"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              background: "transparent",
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Typography
            variant="h6"
            component="h2"
            style={{ textAlign: "center", color: "#f16f1d" }}
          >
            Mock Update
          </Typography>
          <Divider />
          <br />
          <form onSubmit={handleSubmit}>
            <main className={Styles.MainBlockMock}>
              <div className={Styles.formgroup}>
                <label className={Styles.LabelForm}>Student Name</label>
                <h6>{selectedStudent?.username}</h6>
              </div>
              <div className={Styles.formgroup}>
                <div className={Styles.CkeckOld}>
                  <label htmlFor="" className={Styles.LabelForm}>
                    Old Batches
                  </label>

                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="old"
                        checked={isChecked}
                        color="primary"
                        onChange={() => {
                          handleCheckboxChange();
                        }}
                      />
                    }
                    label="Check for Old Batches"
                  />
                </div>
              </div>
            </main>
            {!isChecked && (
              <main>
                <div className={Styles.formgroup}>
                  {/* <label className={Styles.LabelForm}>Select Batch:</label> */}
                  <div className={Styles.selectcontainer}>
                    <FormControl fullWidth className={Styles.selectField}>
                      <Autocomplete
                        freeSolo
                        options={batches}
                        onOpen={handleMenuOpenBatch}
                        value={newBatches}
                        onChange={(event, newValue) => {
                          handleChangeAllField("newBatches", newValue);
                        }}
                        color="warning"
                        size="small"
                        renderInput={params => (
                          <TextField {...params} label="Select Batch *" />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </main>
            )}
            {isChecked && (
              <main className={Styles.MainBlockMock}>
                <>
                  <div className={Styles.formgroup}>
                    <div className={Styles.selectcontainer}>
                      <FormControl fullWidth className={Styles.selectField}>
                        <Autocomplete
                          freeSolo
                          options={Subject}
                          onOpen={handleMenuOpenSubject}
                          value={subject}
                          onChange={(event, newValue) => {
                            handleChangeAllField("subject", newValue);
                          }}
                          color="warning"
                          size="small"
                          renderInput={params => (
                            <TextField {...params} label="Select Subject *" />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className={Styles.formgroup}>
                    <div className={Styles.selectcontainer}>
                      {" "}
                      <FormControl fullWidth className={Styles.selectField}>
                        <Autocomplete
                          freeSolo
                          options={oldBatchList}
                          // onOpen={handleMenuOpenSubject}
                          value={oldBatches}
                          onChange={(event, newValue) => {
                            handleChangeAllField("oldBatches", newValue);
                          }}
                          color="warning"
                          size="small"
                          renderInput={params => (
                            <TextField {...params} label="Select Batch *" />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>
                </>
              </main>
            )}

            <main className={Styles.MainBlockMock}>
              <div className={Styles.formgroup}>
                <div className={Styles.selectcontainer}>
                  <FormControl fullWidth>
                    <InputLabel size="small">Technical Rating*</InputLabel>
                    <Select
                      label="Technical Rating"
                      onOpen={handleMenuOpenTrainer}
                      size="small"
                      required
                      value={technical}
                      onChange={event => {
                        handleChangeAllField("technical", event.target.value);
                      }}
                    >
                      {raiting.map((item, index) => {
                        return (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className={Styles.formgroup}>
                <div className={Styles.selectcontainer}>
                  <FormControl fullWidth>
                    <InputLabel size="small">Communication Rating*</InputLabel>
                    <Select
                      label="Communication Rating"
                      onOpen={handleMenuOpenTrainer}
                      size="small"
                      required
                      value={communication}
                      onChange={event => {
                        handleChangeAllField(
                          "communication",
                          event.target.value
                        );
                      }}
                    >
                      {raiting.map((item, index) => {
                        return (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormControl fullWidth className={Styles.selectField}>
                    <Autocomplete
                      freeSolo
                      options={raiting}
                      onOpen={handleMenuOpenTrainer}
                      value={communication}
                      onChange={(event, newValue) => {
                        handleChangeAllField("communication", newValue);
                      }}
                      color="warning"
                      size="small"
                      renderInput={params => (
                        <TextField {...params} label="Communication Rating *" />
                      )}
                    />
                  </FormControl> */}
                </div>
              </div>
            </main>
            <main className={Styles.MainBlockMock}>
              <div className={Styles.formgroup}>
                <div className={Styles.checkboxContainer}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Comment"
                    multiline
                    minRows={3}
                    value={comment}
                    onChange={event => {
                      handleChangeAllField("comment", event.target.value);
                    }}
                  />
                </div>
              </div>
            </main>

            <main className={Styles.MainBlockMock}>
              <div className={Styles.formgroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="old"
                      checked={checkbox1}
                      color="primary"
                      onChange={() => {
                        handleChangeAllChk("checkbox1", !checkbox1);
                      }}
                    />
                  }
                  label="Final mock"
                />
              </div>
              <div className={Styles.formgroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="old"
                      checked={checkbox2}
                      color="primary"
                      onChange={() => {
                        handleChangeAllChk("checkbox2", !checkbox2);
                      }}
                    />
                  }
                  label="Pre mock"
                />
              </div>
              {checkbox2 && (
                <div className={Styles.formgroup}>
                  <div className={Styles.selectcontainer}>
                    <FormControl fullWidth>
                      <InputLabel size="small">Select Mock Level*</InputLabel>
                      <Select
                        label="Select Mock Level"
                        onOpen={handleMenuOpenMock}
                        size="small"
                        required
                        value={mockLevel}
                        onChange={event => {
                          handleChangeAllField("mockLevel", event.target.value);
                        }}
                      >
                        {mock.map((item, index) => {
                          return (
                            <MenuItem value={item.value} key={index}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {/* <FormControl fullWidth className={Styles.selectField}>
                      <Autocomplete
                        freeSolo
                        options={mock}
                        onOpen={handleMenuOpenMock}
                        value={mockLevel}
                        onChange={(event, newValue) => {
                          handleChangeAllField("mockLevel", newValue);
                        }}
                        color="warning"
                        size="small"
                        renderInput={params => (
                          <TextField {...params} label="Select Mock Level *" />
                        )}
                      />
                    </FormControl> */}
                  </div>
                </div>
              )}
            </main>
            <div>
              <MUIButton
                type="submit"
                size="small"
                color="warning"
                style={{ float: "right" }}
                variant="contained"
                disabled={disableSubmit}
              >
                Send
              </MUIButton>
            </div>
          </form>
        </Box>
      </MUIModal>
      {/* modal for update mock */}

      {loadUser === null ? (
        <h3 style={{ width: "100%", textAlign: "center" }}>
          Something went wrong
        </h3>
      ) : loadUser ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RotatingLines strokeColor="#f16f1d" />
        </div>
      ) : (
        <Paper
          elevation={12}
          sx={{
            width: "98%",
            overflow: "hidden",
            margin: "0 auto",
            position: "relative",
            marginTop: "20px",
          }}
          className={Style.studentListTable}
        >
          <Grid
            container
            spacing={1}
            className={Style.filterBlock}
            alignItems="center"
          >
            <Grid item md={0.5}></Grid>
            <Grid item md={2} position="relative">
              <span className={Style.searchIcon}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#F8931F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                placeholder="Search by student name"
                type="search"
                className={Style.studentNameList}
                value={search}
                onChange={e => handleSearch(e)}
                onKeyPress={handleKeyPressUser}
                onKeyDown={handleBackspaceUser}
              />
            </Grid>
            <Grid item alignItems="center">
              &nbsp;
              <Button
                size="small"
                color="warning"
                sx={{
                  background: "#F87000",
                  color: "#111",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#F87000",
                    color: "#444",
                  },
                }}
                variant="contained"
                onClick={handleSearchClick}
              >
                Search
              </Button>
              &nbsp;&nbsp;
              {filtered.x && (
                <Button
                  sx={{ fontWeight: "bold" }}
                  size="small"
                  onClick={handleReset}
                  color="error"
                  variant="contained"
                >
                  Reset
                </Button>
              )}
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ height: "76vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell size="small" align="left" width="18%">
                    Student Name
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadSearch ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center", width: "100%" }}
                      colSpan={2}
                    >
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : allstudents?.length === 0 ? (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={8}>
                      No Data Found
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  allstudents?.map((ele, i) => {
                    return (
                      <StyledTableRow key={i + 1}>
                        <StyledTableCell
                          scope="row"
                          size="small"
                          align="left"
                          width="80%"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.username}
                          {user?.role1 === "trainer" ? (
                            <>
                              <span> - </span>

                              <span> {ele.number.toString().slice(-4)}</span>
                            </>
                          ) : (
                            <>
                              <span> - </span>

                              <span> {ele.number.toString().slice(-4)}</span>
                            </>
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center" size="small">
                          {user?.role1 === "hr" ||
                          user?.role1 === "human resource" ? (
                            <Link
                              to={`/user-dashboard/studentprofile/${ele?.number}`}
                              state={"true"}
                            >
                              <span className={Style.UserIcon}>
                                <svg width="20" height="20" viewBox="0 0 24 24">
                                  <g
                                    fill="none"
                                    stroke="#fa7826"
                                    strokeWidth="2.5"
                                  >
                                    <path
                                      strokeLinejoin="round"
                                      d="M4 18a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
                                    />
                                    <circle cx="12" cy="7" r="3" />
                                  </g>
                                </svg>{" "}
                              </span>
                            </Link>
                          ) : user?.role1 === "trainer" ? (
                            <>
                              <Button
                                size="small"
                                type="button"
                                className={Style.BtnUpdate}
                                onClick={() => {
                                  setSelectedStudent(ele);
                                  HandleProfile(ele?.number[0]);
                                }}
                              >
                                Update Mock
                              </Button>

                              {/* <Modal open={open} onClose={handleClose}>
                                <Modal.Header>
                                  <Modal.Title className={Styles.HeadingMock}>
                                    Mock Update
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    validationSchema={validationSchema}
                                  >
                                    {({
                                      isSubmitting,
                                      setFieldTouched,
                                      setFieldValue,
                                      touched,
                                      errors,
                                      values,
                                      resetForm,
                                    }) => (
                                      <Form
                                        className={Styles.UpdateMockFormBlock}
                                      >
                                        <main className={Styles.MainBlockMock}>
                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Student Name
                                            </label>
                                            <h6>{selectedStudent?.username}</h6>
                                          </div>
                                          <div className={Styles.formgroup}>
                                            <div className={Styles.CkeckOld}>
                                              <label
                                                htmlFor=""
                                                className={Styles.LabelForm}
                                              >
                                                Old Batches
                                              </label>
                                              <label htmlFor="old">
                                                {" "}
                                                <input
                                                  name="old"
                                                  type="checkbox"
                                                  onChange={e => {
                                                    handleCheckboxChange();
                                                  }}
                                                  checked={isChecked}
                                                />
                                                Check for Old Batches
                                              </label>
                                            </div>
                                          </div>
                                        </main>
                                        {!isChecked && (
                                          <main>
                                            <div className={Styles.formgroup}>
                                              <label
                                                className={Styles.LabelForm}
                                              >
                                                Select Batch:
                                              </label>
                                              <div
                                                className={
                                                  Styles.selectcontainer
                                                }
                                              >
                                                <Field
                                                  onMenuOpen={
                                                    handleMenuOpenBatch
                                                  }
                                                  name="newBatchs"
                                                  component={Select}
                                                  options={batches}
                                                  classNamePrefix="react-select"
                                                  onChange={selectedOptions => {
                                                    if (
                                                      selectedOptions &&
                                                      selectedOptions.value ===
                                                        null
                                                    ) {
                                                      selectedOptions.value = false;
                                                    }

                                                    setFieldValue(
                                                      "newBatchs",
                                                      selectedOptions
                                                    );
                                                  }}
                                                  onBlur={() =>
                                                    setFieldTouched(
                                                      "newBatchs",
                                                      true
                                                    )
                                                  }
                                                />
                                              </div>
                                              <ErrorMessage
                                                name="newBatchs"
                                                component="div"
                                                className={Styles.error}
                                              />
                                            </div>
                                          </main>
                                        )}
                                        {isChecked && (
                                          <main
                                            className={Styles.MainBlockMock}
                                          >
                                            <>
                                              <div className={Styles.formgroup}>
                                                <label
                                                  className={Styles.LabelForm}
                                                >
                                                  Subject:
                                                </label>
                                                <div
                                                  className={
                                                    Styles.selectcontainer
                                                  }
                                                >
                                                  <Field
                                                    onMenuOpen={
                                                      handleMenuOpenSubject
                                                    }
                                                    isLoading={subjectLoading}
                                                    onInputChange={
                                                      handleInputChange
                                                    }
                                                    name="subject"
                                                    component={Select}
                                                    options={Subject}
                                                    classNamePrefix="react-select"
                                                    onChange={selectedOptions => {
                                                      setFieldValue(
                                                        "subject",
                                                        selectedOptions
                                                      );
                                                    }}
                                                    onBlur={() =>
                                                      setFieldTouched(
                                                        "subject",
                                                        true
                                                      )
                                                    }
                                                  />
                                                </div>

                                                {touched.subject &&
                                                errors.subject ? (
                                                  <div className={Styles.error}>
                                                    {errors.subject}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className={Styles.formgroup}>
                                                <label
                                                  className={Styles.LabelForm}
                                                >
                                                  Select Batch:
                                                </label>
                                                <div
                                                  className={
                                                    Styles.selectcontainer
                                                  }
                                                >
                                                  <Field
                                                    name="oldBatches"
                                                    component={Select}
                                                    options={oldBatchList}
                                                    //   isMulti
                                                    classNamePrefix="react-select"
                                                    onChange={selectedOptions => {
                                                      if (isChecked) {
                                                        resetForm();
                                                      } else {
                                                        if (
                                                          selectedOptions &&
                                                          selectedOptions.value ===
                                                            null
                                                        ) {
                                                          selectedOptions.value = false;
                                                        }

                                                        setFieldValue(
                                                          "oldBatches",
                                                          selectedOptions
                                                        );
                                                      }
                                                      setFieldValue(
                                                        "oldBatches",
                                                        selectedOptions
                                                      );
                                                    }}
                                                    onBlur={() =>
                                                      setFieldTouched(
                                                        "oldBatches",
                                                        true
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <ErrorMessage
                                                  name="oldBatches"
                                                  component="div"
                                                  className={Styles.error}
                                                />
                                              </div>
                                            </>
                                          </main>
                                        )}

                                        <main className={Styles.MainBlockMock}>
                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Technical Rating:
                                            </label>
                                            <div
                                              className={Styles.selectcontainer}
                                            >
                                              <Field
                                                onMenuOpen={
                                                  handleMenuOpenTrainer
                                                }
                                                // isLoading={raitingLoading}
                                                name="technical"
                                                component={Select}
                                                options={raiting}
                                                classNamePrefix="react-select"
                                                onChange={selectedOptions => {
                                                  setFieldValue(
                                                    "technical",
                                                    selectedOptions
                                                  );
                                                }}
                                                onBlur={() =>
                                                  setFieldTouched(
                                                    "technical",
                                                    true
                                                  )
                                                }
                                              />
                                            </div>

                                            <ErrorMessage
                                              name="technical"
                                              component="div"
                                              className={Styles.error}
                                            />
                                          </div>

                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Communication Rating:
                                            </label>
                                            <div
                                              className={Styles.selectcontainer}
                                            >
                                              <Field
                                                onMenuOpen={
                                                  handleMenuOpenTrainer
                                                }
                                                // isLoading={raitingLoading}
                                                name="communication"
                                                component={Select}
                                                options={raiting}
                                                classNamePrefix="react-select"
                                                onChange={selectedOptions => {
                                                  setFieldValue(
                                                    "communication",
                                                    selectedOptions
                                                  );
                                                }}
                                              />
                                            </div>
                                            <ErrorMessage
                                              name="communication"
                                              component="div"
                                              className={Styles.error}
                                            />
                                          </div>
                                        </main>
                                        <main className={Styles.MainBlockMock}>
                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Comment:
                                            </label>
                                            <Field
                                              as="textarea"
                                              name="comment"
                                              id="comment"
                                              placeholder="Comment"
                                              className={Styles.textarea}
                                              onBlur={() =>
                                                setFieldTouched(
                                                  "technical",
                                                  true
                                                )
                                              }
                                            />
                                            <ErrorMessage
                                              name="comment"
                                              component="div"
                                              className={Styles.error}
                                            />
                                          </div>
                                        </main>

                                        <main className={Styles.MainBlockMock}>
                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Final Mock
                                            </label>
                                            <div
                                              className={
                                                Styles.checkboxContainer
                                              }
                                            >
                                              <Field
                                                type="checkbox"
                                                name="checkbox1"
                                                id="checkbox1"
                                                checked={values.checkbox1}
                                                onChange={() => {
                                                  setFinalMock(true);
                                                  setFieldValue(
                                                    "checkbox1",
                                                    !values.checkbox1
                                                  );
                                                  setFieldValue(
                                                    "checkbox2",
                                                    false
                                                  );
                                                }}
                                              />

                                              <ErrorMessage
                                                name="checkbox1"
                                                component="div"
                                                className={Styles.error}
                                              />
                                            </div>
                                          </div>
                                          <div className={Styles.formgroup}>
                                            <label className={Styles.LabelForm}>
                                              Pre Mock
                                            </label>
                                            <div
                                              className={
                                                Styles.checkboxContainer
                                              }
                                            >
                                              <Field
                                                type="checkbox"
                                                name="checkbox2"
                                                id="checkbox2"
                                                checked={values.checkbox2}
                                                onChange={() => {
                                                  setFinalMock(false);
                                                  setFieldValue(
                                                    "checkbox2",
                                                    !values.checkbox2
                                                  );
                                                  setFieldValue(
                                                    "checkbox1",
                                                    false
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {!values.checkbox1 && (
                                            <div className={Styles.formgroup}>
                                              <label
                                                className={Styles.LabelForm}
                                              >
                                                Select Mock Level:
                                              </label>
                                              <div
                                                className={
                                                  Styles.selectcontainer
                                                }
                                              >
                                                <Field
                                                  onMenuOpen={
                                                    handleMenuOpenMock
                                                  }
                                                  name="mocklevel"
                                                  component={Select}
                                                  options={mock}
                                                  classNamePrefix="react-select"
                                                  onChange={selectedOptions => {
                                                    setFieldValue(
                                                      "mocklevel",
                                                      selectedOptions
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <ErrorMessage
                                                name="mocklevel"
                                                component="div"
                                                className={Styles.error}
                                              />
                                            </div>
                                          )}
                                        </main>
                                        <button
                                          type="submit"
                                          className={Styles.SearchButton}
                                          disabled={isSubmitting}
                                        >
                                          Send
                                        </button>
                                      </Form>
                                    )}
                                  </Formik>
                                </Modal.Body>
                              </Modal> */}
                            </>
                          ) : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {filtered.x === true ? null : (
            <Grid
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                margin: "0 auto",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <button
                className={Styles.paginationButtons}
                disabled={countedData === 0 ? true : false}
                onClick={() => {
                  const c = countedData - 50;
                  setCountedData(prev => {
                    return prev - 50;
                  });
                  handleChangePage(c);
                }}
              >
                <svg width={20} height={20} viewBox="0 0 48 48">
                  <path
                    fill="#f8931f"
                    d="m30.9 43l3.1-3.1L18.1 24L34 8.1L30.9 5L12 24z"
                  ></path>
                </svg>{" "}
                Previous List
              </button>
              <button
                className={Styles.paginationButtons}
                onClick={() => {
                  const c = countedData + 50;
                  setCountedData(prev => {
                    return prev + 50;
                  });
                  handleChangePage(c);
                }}
                disabled={allstudents.length === 0}
              >
                Next List{" "}
                <svg width={20} height={20} viewBox="0 0 48 48">
                  <path
                    fill="#f8931f"
                    d="M17.1 5L14 8.1L29.9 24L14 39.9l3.1 3.1L36 24z"
                  ></path>
                </svg>
              </button>
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};

export default StudentList;
