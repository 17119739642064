import React from "react";
import { io } from "socket.io-client";

export const socket = io("wss://testchatb.qspiders.com", {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: 10,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});

export const SocketContext = React.createContext();
